module.exports = [{
      plugin: require('/codebuild/output/src600648021/src/bmfonline.co.uk/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-10643212-2","head":true},
    },{
      plugin: require('/codebuild/output/src600648021/src/bmfonline.co.uk/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src600648021/src/bmfonline.co.uk/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src600648021/src/bmfonline.co.uk/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Poppins:200,300,400,600,700"]}},
    },{
      plugin: require('/codebuild/output/src600648021/src/bmfonline.co.uk/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src600648021/src/bmfonline.co.uk/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
